import React from 'react';
import { Link } from 'gatsby'
import ReactWOW from 'react-wow';
function timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    if (rhours > 0 && rminutes > 0) {
        return rhours + "h  " + rminutes + "m";
    } else if (rhours > 0) {
        return rhours + " h";
    } else if (rminutes > 0) {
        return rminutes + " m";
    }
}

const FeatureGrid = (data) => {
    let featureList = data.featureList;
    let featureElements = [];
    if(featureList != null) {
        if (featureList.length > 0) {
            featureList.forEach((feature, index) => {
                let totalDuration = 0
                if (feature.node.outline != null) {
                    if (feature.node.outline.length > 0) {
                        feature.node.outline.forEach(outline => {
                            if(outline.lesson_duration != null) {
                                totalDuration = totalDuration +outline.lesson_duration;
                            }
                        });
                    }
                }
                featureElements.push(
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <Link to={`/features/training/${feature.node._meta.uid}`} className="course-list-item">
                            <div className="tag"><span>Feature</span></div>
                            <img  src={feature.node.safety_image ? feature.node.safety_image.url : ""} alt={feature.node.name[0].text}/>
                            <h5>{feature.node.name[0].text}</h5>
                            <hr />
                            <div className="course-info">
                                <div className="course-info-lessons">Lessons: <span>{feature.node.outline.length}</span></div>
                                <div className="course-info-time">Time: <span>{timeConvert(totalDuration)}</span></div>
                            </div>
                        </Link>
                    </div>
                )
            });
        }
    }

    return(
        <section className="safety_course_area">
            <div className="container">
                <div className="hosting_title safety_title text-center">
                    <ReactWOW animation="fadeInUp">
                        <h2><span>
                    Customized Safety Training  </span>at your Fingertips</h2>
                    </ReactWOW>
                    <p>Sending workers offsite for safety awareness training can cost a company time and money. Eliminate the logistical headaches of training with our flexible online courses. They allow in-house training for multiple users, at different times and locations. What could be easier? All of our courses are included in your subscription and you'll never have to pay more for 'premium courses' or renewals.</p>
                </div>
                <div className="row">
                    {featureElements}
                </div>
                <p id="see_more_courses_a" style={{textAlign: 'center', marginTop: '20px'}}><a href="#see_more_courses"><strong>See more courses</strong> <img style={{height: '20px', width: '20px', marginLeft: '0.5rem'}} src='/landing/pencil.png'/></a></p>
            </div>
        </section>
    )
}

export default FeatureGrid