import React from 'react';
import { Link } from 'gatsby'
import ReactWOW from 'react-wow';

function timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    if (rhours > 0 && rminutes > 0) {
        return rhours + "h  " + rminutes + "m";
    } else if (rhours > 0) {
        return rhours + " h";
    } else if (rminutes > 0) {
        return rminutes + " m";
    }
}

const CourseGridTwo = (data) => {
    let courseList = data.courseList;

    let courseElements = [];
    if(courseList != null) {
        if (courseList.length > 0) {
            courseList.forEach((course, index) => {
                let totalDuration = 0
                if (course.node.outline != null) {
                    if (course.node.outline.length > 0) {
                        course.node.outline.forEach(outline => {
                            if(outline.lesson_duration != null) {
                                totalDuration = totalDuration +outline.lesson_duration;
                            }
                        });
                    }
                }
                courseElements.push(
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <Link to={`/features/training/${course.node._meta.uid}`} className="course-list-item">
                        {/* feature.node.safety_image ? feature.node.safety_image.url : "" */}
                            <img src={course.node.safety_image ? course.node.safety_image.url : ""} alt={course.node.name[0].text} />
                            <h5>{course.node.name[0].text}</h5>
                            <hr />
                            <div className="course-info">
                                <div className="course-info-lessons">Lessons: <span>{course.node.outline.length}</span></div>
                                <div className="course-info-time">Time: <span>{timeConvert(totalDuration)}</span></div>
                            </div>
                        </Link>
                    </div>
                )
            });
        }
    }
    return (
        <section className="safety_course_area no-padding">
            <div className="container">
                <div className="row">
                    {courseElements}
                </div>
            </div>
        </section>
    )
}

export default CourseGridTwo