import React, { useEffect } from 'react';
import Layout from '../../../components/layouts';
import Banner from '../../../components/common/Banner'
// import TeamGrid from '../../components/about/the-team/TeamGrid'
import FeatureGrid from '../../../components/features/training/FeatureGrid'
import Promo from '../../../components/common/Promo'
import CourseGrid from '../../../components/features/training/CourseGrid';
import CourseGridTwo from '../../../components/features/training/CourseGridTwo';
import SEO from '../../../components/common/SEO'

// export const query = graphql`
// {
//   featured: prismic{
//     allCourses(where: {type: "Featured"}) {
//       edges {
//         node {
//           _meta {
//             uid
//           }
//           name
//           outline {
//             lesson_duration
//           }
//           safety_image
//         }
//       }
//     }
//   }
//   nextTwenty: prismic {
//     allCourses(where: {type: "General"}, after: "YXJyYXljb25uZWN0aW9uOjE5", first: 20) {
//       edges {
//         node {
//           name
//           outline {
//             lesson_duration
//           }
//           safety_image
//           _meta {
//             uid
//             id
//           }
//         }
//         cursor
//       }
//       totalCount
//       pageInfo {
//         endCursor
//         hasPreviousPage
//         startCursor
//         hasNextPage
//       }
//     }
//   }
//   general : prismic{
//     allCourses(where: {type: "General"}) {
//       edges {
//         node {
//           _meta {
//             uid
//           }
//           name
//           outline {
//             lesson_duration
//           }
//           safety_image
//         }
//       }
//     }
//   }
// }
// `

export const query = graphql`
query MyQuery($after: String) {
  prismic {
    allCourses(first: 100, after: $after, where: {type: "General"}) {
      edges {
        node {
          _meta {
            uid
          }
          name
          outline {
            lesson_duration
          }
          safety_image
        }
      }
    }
  }
  featured: prismic {
    allCourses(where: {type: "Featured"}) {
       edges {
        node {
          _meta {
            uid
          }
          name
          outline {
            lesson_duration
          }
          safety_image
        }
      }
    }
  }
}
`

export default ({ data }) => {
  // BANNER PROPS
  const title = () => (
    <div>
      <span>Over 50 </span>Free<br /> Safety Training <span>Courses</span>
    </div>
  )
  const bannerProps = {
    title: title,
    subtitle: "Features > Training",
    bannerImage: "/features/training-courses/course-list-header.jpg",
    buttonLeft: {
      text: "More Features",
      link: "/features"
    },
    // buttonRight: {
    //   text: "Custom Courses",
    //   link: "/features"
    // }
  }

  // console.log(data)


  const featureList = data.featured.allCourses.edges;
  const courseList = data.prismic.allCourses.edges;


  const seo = {
    title: "Workhub | 60+ Online Safety Training Courses | Free EHS Management Software",
    description:
      "Deep-dive into our 60+ free online safety training courses. Workhub offers customization, compliance tracking & training courses for a wide-range of industries.",
  }

  const PromoText = {
    title: "What is Workhub?",
    text:
      "We provide complete health and safety compliance software that allows easy management of training, procedures, policies, inspections, and more, centralized in one easy-to-use hub.",
  }

  return (
    <Layout>
         <SEO {...seo}/>
      <Banner {...bannerProps} />
      {/* <TeamGrid /> */}
      <FeatureGrid featureList={featureList} />
      <Promo promo={PromoText}/>
      <CourseGrid courseList={courseList} />
      {/* <CourseGridTwo courseList={nextTwenty} /> */}
    </Layout>
  )
}
